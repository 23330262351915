'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.locations
 # @description

###
angular
  .module 'mundoAdmin.locations', [
    'ui.router'
  ]
